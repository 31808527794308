import { combineReducers } from "redux";
import loginReducer from './loginReducer'
import dataReducer from './dataReducer'
import archiveDataReducer from './archiveDataReducer'


const allReducers = combineReducers({
    login: loginReducer,
    data: dataReducer,
    archiveData: archiveDataReducer
})

export default allReducers
import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';
import React from 'react';
import './App.css';

import Router from 'Env/Router/router';
import { auth } from "Env/firebase";
import theme from "Env/theme";
import Login from 'Views/Login/Login';
import { connect } from 'react-redux';
import { loginInProps, logoutInProps } from './Redux/actions';

class App extends React.Component {
  componentDidMount() {
   auth.onAuthStateChanged(async user => {
      if (user) {
        user.email === "jeremy.couillet@gmail.com" && this.props.loginInProps()
        user.email === "maximiliencastel@gmail.com" && this.props.loginInProps()
        user.email === "normandieparking@gmail.com" && this.props.loginInProps()
      }
      else {
       this.props.logoutInProps()
      }
    })
  }

  render() {
    const {loggedIn} = this.props
    return (
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
          {loggedIn ? <Router/> : <Login />}
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn : state.login
})
export default connect(mapStateToProps, {loginInProps, logoutInProps})(App);

import React from 'react';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { OpenInNew } from '@mui/icons-material'
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { getAllBookings, retrieveArchive } from "Functions/data"
import moment from 'moment'
import { connect } from 'react-redux'
import StatusIcon from 'Components/StatusIcon/StatusIcon'
import { withRouter } from 'Components/WithRouter';

const styles = theme => ({
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  tableRow: {
    cursor: 'pointer',
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

class MuiVirtualizedTable extends React.PureComponent {


  static defaultProps = {
    headerHeight: 48,
    rowHeight: 48,
  };
  state = {
    data: []
  }


  getRowClassName = ({ index }) => {
    const { classes } = this.props;
    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.tableRowHover]: index !== -1,
    });
  };

  URLIcon = ({ ID }) => (
    <OpenInNew onClick={(e) => {
      e.stopPropagation()
      retrieveArchive(ID)
    }} />
  )

  cellRenderer = (props) => {
    const { classes, rowHeight } = this.props;
    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer)}
        variant="body"
        style={{ height: rowHeight }}
      >
        {props.cellData}
      </TableCell>
    );
  };

  headerRenderer = ({ label, columnIndex }) => {
    const { headerHeight, classes } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
      >
        <span>{label}</span>
      </TableCell>
    );
  };

  getData = async () => {
    try {
      const fetchData = await getAllBookings()
      this.setState({ data: fetchData })
    } catch (error) {

      console.log('error:', error)
    }
  }

  render() {
    const { classes, rowHeight, headerHeight, history, ...tableProps } = this.props;
    const { data } = this.props
    return (
      <Paper style={{ width: '100%', height: '90%' }}>
        <AutoSizer>
          {({ height, width }) => (
            <Table
              height={height}
              rowCount={data.length}
              rowGetter={({ index }) => data[index]}
              width={width}
              rowHeight={rowHeight}
              headerHeight={headerHeight}
              {...tableProps}
              rowClassName={this.getRowClassName(true)}
              onRowClick={({ rowData }) => this.props.navigate(`/editor/${rowData.docID}?archive=true`, { state: { rowData } })}
             
            >
              <Column
                cellDataGetter={({ dataKey, rowData }) => {
                  return (rowData.bookingDate = rowData.bookingData ? moment.unix(rowData.bookingData[dataKey]).format('DD-MM-YYYY') : rowData.bookingDate)
                }}
                dataKey="seconds"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 0
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Date de résa'
              />
              <Column
                cellDataGetter={({ dataKey, rowData }) => {
                  return <StatusIcon data={rowData} />
                }}
                dataKey="status"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 0
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Statut'
              />
              <Column
                cellDataGetter={({ dataKey, rowData }) => {
                  return `${rowData.personalInfo.surname} ${rowData.personalInfo.name}`
                }}
                dataKey="client"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 0
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Client'
              />
              <Column
                cellDataGetter={({ dataKey, rowData }) => {

                  return ` ${rowData.dates.startDate} (${rowData.flights.arrivingTime})`
                }}
                dataKey="depart"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 1
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Départ'
              />
              <Column
                cellDataGetter={({ dataKey, rowData }) => {

                  return ` ${rowData.dates.endDate} (${rowData.flights.returningTime})`
                }}
                dataKey="retour"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 2
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Retour'
              />
              <Column
                cellDataGetter={({ dataKey, rowData }) => {

                  return ` ${rowData.car.carType} (${rowData.car.immatriculation})`
                }}
                dataKey="vehicule"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 2
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Véhicule'
              />
              <Column
                cellDataGetter={({ dataKey, rowData }) => {

                  return <this.URLIcon ID={rowData.docID} />
                }}
                dataKey="republish"
                headerRenderer={headerProps =>
                  this.headerRenderer({
                    ...headerProps,
                    columnIndex: 3
                  })
                }
                className={classes.flexContainer}
                cellRenderer={this.cellRenderer}
                width={200}
                label='Re-publier'
              />
            </Table>
          )}
        </AutoSizer>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  data: state.archiveData
})
const ConnectedVirtualizedTable = connect(mapStateToProps, {})(MuiVirtualizedTable)
export default withRouter(withStyles(styles)(ConnectedVirtualizedTable));



import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Drawer from 'Views/Drawer/Drawer';
import Booking from "Views/Booking/Booking";
import Editor from "Views/Editor/Editor";
import Calendrier from 'Views/CalendarEditor/CalendarEditor';
import Archive from "Views/Archives";

export default function App() {
  return (
    <BrowserRouter>
      <Drawer style={{ height: "100%" }}>
        <Routes>
          <Route path="/" element={<Booking />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/editor/:data" element={<Editor />} />
          <Route path="/calendrier" element={<Calendrier />} />
          <Route path="/archives" element={<Archive />} />
          
        </Routes>
      </Drawer>
    </BrowserRouter>
  );
}

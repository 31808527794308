export const setData = (data) => {
    return {
        type: "SET_DATA",
        payload: data
    }
    }

export const setArchiveData = (data) => {
    return {
        type : "SET_ARCHIVE_DATA",
        payload: data
    }
}
import React from 'react'
import styles from './BackButton.module.css'
import { Typography } from '@mui/material';
import { ArrowBack } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';

export default ({ history }) => {
    const navigate = useNavigate();
    return (
        <div className={styles.container} onClick={() => navigate(-1)}>
            <ArrowBack color='primary' className={styles.icon} />
            <Typography variant="subtitle1" color='primary'>Retour</Typography>
        </div>
    )
}

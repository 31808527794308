const API = process.env.NODE_ENV ==='development'?
  "http://localhost:3000/api/payment" 
  : "https://resa.normandieparking.com/api/payment"


const cloudFunctionsURl = {
    draft: `${API}/draft`,
    refund: `${API}/refund`,
}

export default cloudFunctionsURl
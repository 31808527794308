import { createTheme } from '@mui/material/';

export const mainColor = "#0D4FA9";
const disabledColor = "rgba(0, 0, 0, 0.38);"
const defaultTheme = createTheme();

const theme = createTheme({
  palette: {
    primary: {
      main: '#0D4FA9'
    },
    secondary: {
      main: "#ffffff"
    }
  },
  typography: {
    
    body1: {
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: "12px"
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: "14px"
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: "15px"
      },
    },
    body2: {
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: "11px"
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: "12px"
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: "13px"
      },
    },
    h1: {
      fontFamily: "Rubik Variable",
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: "6vw"
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: "2.5vw"
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: "2.2vw"
      },
    },
    h3: {
      fontFamily: "Rubik Variable",
      fontWeight: 'bold',
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: "5.5vw"
      },
      [defaultTheme.breakpoints.up('sm')]: {
        fontSize: "2.7vw"
      },
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: "2vw"
      },
      margin: defaultTheme.spacing(2)
    },
  },
  components: {

    MuiTypography: {
      defaultProps: {
        fontFamily: "Roboto Mono Variable"
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          color: disabledColor,
          [defaultTheme.breakpoints.down('md')]: {
            fontSize: "14px"
          },
          [defaultTheme.breakpoints.up('sm')]: {
            fontSize: "1.7vw"
          },
          [defaultTheme.breakpoints.up('md')]: {
            fontSize: "1.2vw"
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: `${disabledColor}!important`,
          "&$focused": {
            color: mainColor
          },
        },
        filled: {
          color: `${mainColor}!important`
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: mainColor
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          borderBottomColor: 'red!important'
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: disabledColor,
          '&$checked': {
            color: mainColor,
          },
        },
        colorSecondary: {
          color: disabledColor,
          '&$checked': {
            color: mainColor,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: disabledColor,
        },
      },
    },
  }
});

export default theme;

export const statusProps = {
    validStatus : 'Acceptée',
    canceledStatus : 'Annulée',
    finalizedStatus:'Finalisée',
    paidStatus : 'Payée',
    refundedStatus : 'Remboursée',
    newOrderStatus: 'Nouvelle',
    cashPaymentAskedStatus: 'Paiement à valider'
};

export const appUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://resa.normandieparking.com'
// Supports weights 100-700
import '@fontsource/rubik';
// Supports weights 300-900
import '@fontsource-variable/rubik';
import '@fontsource-variable/roboto-mono'
import '@fontsource/roboto-mono';
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./Redux/store";

const ReduxApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
};
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<ReduxApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// @flow
import React from 'react'
import styles from './StatusIcon.module.css'
import { Payment, AccessTime, AddAlert, Check, Close, KeyboardReturn } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { statusProps } from "Env/constantes"

const colors = {
    [statusProps.paidStatus]: '#38ada9',
    [statusProps.validStatus]: '#ff9f43',
    [statusProps.finalizedStatus]: '#ff9f43',
    [statusProps.cashPaymentAskedStatus]: '#ff9f43',
    [statusProps.newOrderStatus]: '#ff6b6b',
    [statusProps.canceledStatus]: '#576574',
    [statusProps.refundedStatus]: '#576574',
}
const IconType = (props) => {
    switch (props.status) {
        case statusProps.paidStatus:
            return <Payment {...props} />
        case statusProps.cashPaymentAskedStatus:
            return <AccessTime {...props} />
        case statusProps.newOrderStatus:
            return <AddAlert {...props}/>
        case statusProps.validStatus:
            return <Check {...props}/>
        case statusProps.canceledStatus:
            return <Close {...props}/>
        case statusProps.refundedStatus:
            return <KeyboardReturn {...props}/>
        default:
            return null
    }
}

const Icon = ({ status }) => (<div className={styles.iconContainer} style={{ backgroundColor: colors[status] }}>
    <IconType status={status} className={styles.icon} color='secondary' />
    <Typography color='secondary'>{status}</Typography>
</div>)


export default ({ data }) => {
    return (
        <Icon status={data.status} />
    )
}

import React from 'react'
import { DayPickerSingleDateController } from 'react-dates'
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './Calendar.css'
import styles from './Calendar.module.css'
import moment from 'moment'
import { Snackbar, Grow, SnackbarContent, CircularProgress } from '@mui/material'
import "moment/locale/fr"
import _ from 'lodash'
import { setBlockedDates, getBlockedDates } from 'Functions/data'


moment.locale('fr')
const format = 'YYYY-MM-DD'

export default class Calendar extends React.Component {
    constructor(props) {
        super(props)
        this.setBlockDates = _.debounce(this.setBlockDates, 2000)
        this.state = {
            date: null,
            blocked: null,
        }
    }

    componentDidMount = async () => {
        let data = await getBlockedDates()
        this.setState({ blocked: data })
    }

    setBlockDates = async () => {
        await setBlockedDates(this.state.blocked)
        this.setState({ loading: false })
        setTimeout(() => this.setState({ open: false }), 1000)
    }

    _handleChange = (date) => {
        this.setState({ loading: true, open: true })
        const { blocked } = this.state
        const val = date.format(format)
        if (blocked.includes(val)) {
            let newBlock = [...blocked]
            newBlock.splice(blocked.indexOf(val), 1)
            this.setState({ blocked: newBlock })
        }
        else this.setState({ blocked: [...blocked, date.format(format)] })
        this.setBlockDates()
    }

    _isHighlighted = (d) => {
        return moment().isAfter(d, 'day') ? false : this.state.blocked.includes(d.format(format))
    }
    render() {
        const { loading, open } = this.state
        const { blocked } = this.state
        return (
            blocked ? <div className={styles.container}>
                <DayPickerSingleDateController
                    hideKeyboardShortcutsPanel
                    isOutsideRange={d => moment().isAfter(d, 'day')}
                    isDayBlocked={d => moment().isSame(d, 'day')}
                    numberOfMonths={2}
                    date={this.state.date} // momentPropTypes.momentObj or null
                    onDateChange={this._handleChange} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    isDayHighlighted={(d) => this._isHighlighted(d)}
                />

                <Grow in={open}>
                    <Snackbar open={open}
                        onClose={() => this.setState({ open: false })}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}>
                        <SnackbarContent
                            className={styles.snackbar}
                            style={{ backgroundColor: loading ? '#f7b731' : '#20bf6b' }}
                            open={open}
                            onClose={() => this.setState({ open: false })}
                            message={loading ? <span>Sauvegarde...</span> : loading === false && <span>Modifications enregistrées</span>}
                        />

                    </Snackbar>
                </Grow>


            </div>

                : <CircularProgress color="secondary" />
        )
    }
}

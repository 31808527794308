// This file is shared across the demos.

import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Typography,
} from "@mui/material";
import { AccountBox, DirectionsCar, CalendarToday, Book } from "@mui/icons-material";
import { NavLink } from "react-router-dom";

import styles from "./menuItems.module.css"
import { auth } from "Env/firebase";

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

class MenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <NavLink to="/booking" className={styles.inactive} >
          <ListItem button>
            <ListItemIcon>
              <DirectionsCar color="primary" />
            </ListItemIcon>
            <ListItemText primary="Réservations" />
          </ListItem>
        </NavLink>
        <NavLink to="/calendrier" className={styles.inactive} >
          <ListItem button>
            <ListItemIcon>
              <CalendarToday color="primary" />
            </ListItemIcon>
            <ListItemText primary="Calendrier" />
          </ListItem>
        </NavLink>
        <NavLink to="/archives" className={styles.inactive} >
          <ListItem button>
            <ListItemIcon>
              <Book color="primary" />
            </ListItemIcon>
            <ListItemText primary="Archives" />
          </ListItem>
        </NavLink>

     
        <ListItem button onClick={() => this.setState({ open: true })}>
          <ListItemIcon>
            <AccountBox color="primary" />
          </ListItemIcon>
          <ListItemText primary="Deconnexion" />
        </ListItem>

        <Dialog
          fullScreen={this.props.fullScreen}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <Typography variant='h3' color='primary'>
                Bah Bravo!
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Veux-tu te déconnecter (Looser) ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Plus tard...
            </Button>
            <Button
              onClick={() => {
               auth.signOut();
              }}
              color="primary"
              autoFocus
            >
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withMobileDialog()(MenuItems);

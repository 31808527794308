export const loginInProps = () => {
return {
    type: "LOGIN"
}
}

export const logoutInProps = () => {
    return {
        type: "LOGOUT"
    }
}
import React, { Component } from 'react';
import { Typography, Card, Button } from '@mui/material';
import { signIn } from "Functions/auth"
import './Login.css'

export default class componentName extends Component {
  render() {
    return (
      <div className="container">
        <Card className="logCard">
          <Typography variant="h3" color='primary'>
            Bienvenue
    </Typography>
          <Typography variant="caption">Cliquer ici pour se connecter</Typography>
          <Button
            color="primary"
            variant="contained"
            onClick={signIn}
            style={{
              width: "100%",
              marginLeft: 2,
              marginRight: 2,
              marginTop: 5
            }}
          >
            <svg style={{ width: 16, height: 16, paddingRight: 8 }} viewBox="0 0 24 24">
              <path
                fill="#fff"
                d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
              />
            </svg>
            LOGIN
          </Button>

        </Card>
      </div>

    );
  }
}

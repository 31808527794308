import React from "react";
import PropTypes from "prop-types";
import { Drawer, AppBar, Toolbar, List, Typography, IconButton, Hidden, Divider } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import MenuIcon from "@mui/icons-material/Menu";
import MenuItems from "Env/Router/menuItems"
import {subscribe} from 'Functions/data'


const drawerWidth = 240;

const styles = theme => {
  
  return ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "scroll",
    position: "relative",
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    height:'100%'
    },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    boxShadow:"0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
  },
  toolbar2: {...theme.mixins.toolbar,
 },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative"
    }
  },
  content: {
    flex:1,
    marginTop:theme.mixins.toolbar.minHeight,
    display:'flex',
    overflow:'auto',
    alignItems: 'flex-start',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    maxWidth: "80%",
    objectFit: "contain",
    alignSelf:'center'
  }
});
}

class Navigation extends React.Component {
  state = {
    mobileOpen: false
  };
 
  

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  componentDidMount = () => {
    this.subscription = subscribe()
}

// componentWillUnmount = () => {
//   this.subscription()
// }
  render() {
    const { classes, theme, children } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar}>
          <div>
            <img
              className={classes.logo}
              src={require("../../Assets/logo.png")}
              alt="logo"
              style={{maxHeight:"75px"}}
              
            />
          </div>
        </div>

        <Divider />
        <List><MenuItems/></List>
      </div>
    );

    return (
      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.navIconHide}
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              Normandie Parking
            </Typography>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={this.state.mobileOpen}
            onClose={this.handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          <div className={classes.toolbar2} />
          {children}
        </main>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};



export default withStyles(styles, { withTheme: true })(Navigation);

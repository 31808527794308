import firebase, { firestore } from "Env/firebase";
import store from "../Redux/store";
import { setData, setArchiveData } from "../Redux/actions";
import axios from "axios";
import moment from "moment";
import {DateTime} from 'luxon'
import API from "Functions/cloudFunctions";
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, setDoc } from "firebase/firestore";
const db = collection(firestore, process.env.NODE_ENV === 'production' ? "resa" : "resa");
const archive = collection(firestore, "archives");
const dbAdmin = collection(firestore, "admin");

export const getAllBookings = async () => {
  try {
    const querySnapshot = await getDocs(db);
    const res = querySnapshot.docs.map(doc => ({
      docID: doc.id,
      ...doc.data()
    }));
    return res;
  } catch (error) {
    console.log("error:", error);
  }
};

export const archiveBooking = async (data) => {
  try {
    const docRef = doc(archive, data.docID);
    await setDoc(docRef, data);
    await deleteDoc(doc(db, data.docID));
  } catch (error) {
    console.log("error:", error);
  }
};

export const getAllArchives = async () => {
  try {
    const querySnapshot = await getDocs(archive);
    const res = querySnapshot.docs.map(doc => ({
      docID: doc.id,
      ...doc.data()
    }));
    return res;
  } catch (error) {
    console.log("error:", error);
  }
};

export const retrieveArchive = async (docID) => {
  try {
    const docRef = doc(archive, docID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      await setDoc(doc(db, docID), data);
      await deleteDoc(doc(archive, docID));
    } else {
      alert("n'existe pas");
    }
  } catch (error) {
    console.log("error:", error);
  }
};

export const subscribe = async () => {
  try {
    onSnapshot(db, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        return { docID: doc.id, ...doc.data() };
      });
      data.sort(function(a, b) {
        const aDate = DateTime.fromFormat(a.dates.startDate, 'dd-MM-yyyy');
        const bDate = DateTime.fromFormat(b.dates.startDate, 'dd-MM-yyyy');
        return aDate - bDate;
      });
      store.dispatch(setData(data));
    });

    onSnapshot(archive, (querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => {
        return { docID: doc.id, ...doc.data() };
      });
      store.dispatch(setArchiveData(data));
    });
  } catch (error) {
    console.log("error:", error);
  }
};

export const validateBooking = async (data) => {
  try {
    await axios.post(
      API.draft,
      {
        ...data,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (error) {
    console.log("error:", error);
  }
};

export const cancelBooking = async (data) => {
  try {
    const req = await axios.delete(
      API.draft,
      {
        data: {
          invoiceID: data.billing.invoiceID,
          docID: data.docID,
        },
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    console.log("data:", req.data);
  } catch (error) {
    console.log("error:", error?.response?.data);
  }
};

export const refundPayment = async (data) => {
  try {
    await axios.post(
      API.refund,
      {
        invoiceID: data.billing.invoiceID,
        docID: data.docID,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (error) {
    console.log("error:", error);
  }
};

export const validateCashPayment = async (data) => {
  try {
    await axios.post(
      API.payCashUrl,
      {
        invoiceID: data.billing.invoiceID,
        docID: data.docID,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
  } catch (error) {
    console.log("error:", error.response.data);
  }
};

export const setBlockedDates = async (data) => {
  try {
    const filter = data.filter((date) => moment().isSameOrBefore(date, "day"));
    const document =  doc(firestore, "admin", "blockedDays");
    await setDoc(document, { dates: filter });
    return true;
  } catch (error) {
    console.log("error:", error);
    return false;
  }
};

export const getBlockedDates = async () => {
  try {
    const document =  doc(firestore, "admin", "blockedDays");
    const query = await getDoc(document);
    if (query.exists()) {
      const data = query.data().dates;
      return data;
    } else {
      console.log("No such document!");
      return [];
    }
  } catch (error) {
    console.log("error:", error);
    return [];
  }
};

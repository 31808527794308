import { getAuth, signInWithRedirect,  GoogleAuthProvider, setPersistence, browserLocalPersistence, signInWithPopup } from 'firebase/auth';
import { app } from 'Env/firebase'; // Assurez-vous que 'app' est correctement exporté dans votre fichier de configuration Firebase

const auth = getAuth(app);

export const signIn = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
        'prompt': 'consent'
    });
    try {
        await setPersistence(auth, browserLocalPersistence);
        await signInWithPopup(auth, provider);
    } catch (error) {
        console.log(error);
    }
};

export const signOutUser = async () => {
    try {
        await auth.signOut()
    } catch (error) {
        console.log(error);
    }
};



import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyA54w307G6c4_X0NjEGtsYmSFoPqd5N4cA",
  authDomain: "normandieparkingfb.firebaseapp.com",
  databaseURL: "https://normandieparkingfb.firebaseio.com",
  projectId: "normandieparkingfb",
  storageBucket: "normandieparkingfb.appspot.com",
  messagingSenderId: "968588463093",
  appId: "1:968588463093:web:9a8d654c2d2c203f",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);

export { app, firestore, auth };
export default app

import { Box, Paper, Typography } from "@mui/material";
import BackButton from "Components/BackButton/BackButton";
import EditorButtons, {
  InvoiceButton,
} from "Components/EditorButtons/EditorButtons";
import moment from "moment";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Editor.css";

export default (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  
  const [data, setData] = React.useState(null);
  React.useEffect(() => {
  
    setData(location.state?.rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const TextBlock = ({ title, data, divider }) => (
    <div className="textBlock" style={divider && { marginTop: 19 }}>
      <Typography variant="body1" color="primary" className="title">
        {title}:{" "}
      </Typography>
      <Typography variant="body1">&nbsp;{data}</Typography>
    </div>
  );
  const EmailBlock = ({ email }) => (
    <div className="textBlock">
      <Typography color="primary" className="title">
        Email:&nbsp;
      </Typography>
      <Typography>
        <a href={`mailto:${email}`}>{email}</a>
      </Typography>
    </div>
  );
  const PhoneBlock = ({ tel }) => (
    <div className="textBlock">
      <Typography color="primary" className="title">
        Téléphone:&nbsp;
      </Typography>
      <Typography>
        <a href={`tel:${tel}`}>{tel}</a>
      </Typography>
    </div>
  );
  const getGender = (rawValue) => {
    switch (rawValue) {
      case "male":
        return "Mr";
      case "female":
        return "Mme";
      case "other":
        return "Autre";
      default:
        return "Mr";
    }
  };

  const getPrice = () => {
    const { daysBooked, pricing, total } = data.price;
    const { options } = data;
    let values = Object.values(options);
    let optionsTotal =
      values.length &&
      values.reduce((t, { value, price }) => t + (value ? price : 0), 0);
    let totalPrice = total + optionsTotal;
    return `Durée : ${daysBooked} jours | Tarif : ${pricing}€/jour | Total (avec options) : ${totalPrice.toFixed(
      2
    )}€`;
  };
  if (data) {
    const {
      bookingData,
      bookingDate,
      dates,
      car,
      personalInfo,
      flights,
      status,
    } = data;

    const options = Object.keys(data?.options).reduce((acc, val) => {
      return data.options[val].value
        ? [...acc, { option: val, ...data.options[val] }]
        : acc;
    }, []);

    
    
    return (
      <div className="editor">
        <Paper className="paperBlock">
          <BackButton />
          <div className="editorContent">
            <TextBlock title="Statut" data={status} />
            <TextBlock
              title="Date de réservation"
              data={
                bookingData
                  ? moment.unix(bookingData.seconds).format("DD-MM-YYYY")
                  : bookingDate
              }
              divider
            />
            <TextBlock
              title="Pour"
              data={`${getGender(personalInfo?.gender)} ${
                personalInfo?.surname
              } ${personalInfo?.name}`}
            />
            <PhoneBlock tel={personalInfo?.phone} />
            <EmailBlock email={personalInfo?.email} />
            <TextBlock
              title="Adresse"
              data={`${personalInfo?.address}, ${personalInfo?.postcode} - ${personalInfo?.city}`}
            />
            <TextBlock
              title="Départ"
              data={`${dates?.startDate} (${flights?.arrivingTime})`}
              divider
            />
            <TextBlock
              title="Vol aller"
              data={`${flights?.compagnieAller?.name} ${
                flights?.volAller
                  ? flights?.compagnieAller?.prefix + flights?.volAller
                  : "(pas de numéro de vol)"
              }`}
            />
            <TextBlock
              title="Retour"
              data={`${dates?.endDate} (${flights?.returningTime})`}
              divider
            />
            <TextBlock
              title="Vol retour"
              data={`${
                flights?.volRetour
                  ? flights?.volRetour
                  : "(pas de numéro de vol)"
              } - Depuis ${flights?.fromDestination}`}
            />
            <TextBlock
              title="Véhicule"
              data={`${car?.carType} (${car?.immatriculation})`}
              divider
            />
            <TextBlock
              title="Nombre de passagers Aller"
              data={`${car?.passengers}`}
            />
            <TextBlock
              title="Nombre de passagers Retour"
              data={`${car?.passengers_return ?? 'inconnu'}`}
            />
            {car?.conditions && (
              <TextBlock title="Conditions" data={car?.conditionsComment} />
            )}
            {options.length > 0 && (
              <Box display="flex" mt={2}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: "1.1em" }}
                  color="primary"
                >
                  Options :
                </Typography>
              </Box>
            )}
            {options.map((o, i) => (
              <TextBlock key={i} title={o.title} data={o.price + "€"} />
            ))}
            {/* <TextBlock title='Hors gabarit' data={`${options.oversize?.value ? 'oui' : 'non'}`} divider/>
                    <TextBlock title='Recharge électrique' data={`${options.electricCharge?.value ? 'oui' : 'non'}`} />
                    <TextBlock title='Assurance annulation' data={`${options.insuranceOption?.value ? 'oui' : 'non'}`} /> */}
            <TextBlock title="Prix calculé" data={getPrice()} divider />
            <InvoiceButton data={data} />
          </div>
        </Paper>
        <EditorButtons
          data={data}
          status={data.status}
          nav={() => navigate("/booking")}
        />
      </div>
    );
  } else return null;
};

import React from "react";
import styles from "./EditorButtons.module.css";
import { Button } from "@mui/material";
import {
  Delete,
  Check,
  Close,
  Payment,
  CloudDownload,
  RemoveRedEye,
  Receipt,
} from "@mui/icons-material";
import {
  archiveBooking,
  validateBooking,
  cancelBooking,
  refundPayment,
  validateCashPayment,
} from "Functions/data";
import { statusProps } from "Env/constantes";

export const InvoiceButton = ({ data }) => {
  if (!data.billing) {
    return null;
  } else {
    const { hosted_url, pdf_url } = data.billing;
    const { payment } = data;
    const buttons = (
      <div className={styles.editorButtonsContainer}>
        {hosted_url && (
          <Button
            variant="outlined"
            color="primary"
            className={styles.button}
            onClick={() => window.open(hosted_url)}
          >
            <RemoveRedEye className={styles.buttonIcon} />
            Voir la facture
          </Button>
        )}
        {pdf_url && (
          <Button
            variant="outlined"
            color="primary"
            className={styles.button}
            onClick={() => window.open(pdf_url)}
          >
            <CloudDownload className={styles.buttonIcon} />
            Télécharger pdf
          </Button>
        )}
        {payment && payment.receipt && (
          <Button
            variant="outlined"
            color="primary"
            className={styles.button}
            onClick={() => window.open(payment.receipt)}
          >
            <Receipt className={styles.buttonIcon} />
            Voir le reçu
          </Button>
        )}
      </div>
    );
    return buttons;
  }
};

const ValidationButton = ({ status, data, closePage }) => {
  let valid = (
    <Button
      className={styles.button}
      variant="contained"
      color="primary"
      style={{ backgroundColor: "#1dd1a1" }}
      onClick={() => {
        validateBooking(data);
        closePage();
      }}
    >
      <Check className={styles.buttonIcon} />
      Valider
    </Button>
  );
  let cancel = (
    <Button
      className={styles.button}
      variant="contained"
      color="primary"
      style={{ backgroundColor: "#ff9f43" }}
      onClick={() => {
        cancelBooking(data);
        closePage();
      }}
    >
      <Close className={styles.buttonIcon} />
      Annuler
    </Button>
  );
  let refund = (
    <Button
      className={styles.button}
      variant="contained"
      color="primary"
      style={{ backgroundColor: "#ff9f43" }}
      onClick={() => {
        refundPayment(data);
        closePage();
      }}
      disabled={data.payment && data.payment.type === "Cash"}
    >
      <Payment className={styles.buttonIcon} />
      Rembourser
    </Button>
  );

  switch (status) {
    case statusProps.validStatus:
      return cancel;
    case statusProps.canceledStatus:
      return valid;
    case statusProps.finalizedStatus:
      return cancel;
    case statusProps.paidStatus:
      return refund;
    case statusProps.cashPaymentAskedStatus:
      return cancel;
    case statusProps.refundedStatus:
      return null;
    default:
      return valid;
  }
};

const CashButton = ({ data, closePage }) => {
  return (
    statusProps.cashPaymentAskedStatus === data.status && (
      <Button
        className={styles.button}
        color="primary"
        variant="contained"
        onClick={() => {
          validateCashPayment(data);
          closePage();
        }}
        style={{ backgroundColor: "#1dd1a1" }}
      >
        <Check className={styles.buttonIcon} />
        Paiement reçu
      </Button>
    )
  );
};

export default ({ status, data, nav }) => {
  const deleteData = async (data) => {
    try {
      await archiveBooking(data);
      nav();
    } catch (error) {
      console.log("error:", error);
    }
  };

  return (
    <div className={styles.editorButtonsContainer}>
      <ValidationButton status={status} data={data} closePage={() => nav()} />
      <CashButton data={data} closePage={() => nav()} />
      <Button
        className={styles.button}
        variant="contained"
        color="primary"
        style={{ backgroundColor: "#fc5c65" }}
        onClick={() => deleteData(data)}
      >
        <Delete className={styles.buttonIcon} />
        Archiver
      </Button>
    </div>
  );
};
